
import { BANNERS_PRODUCTS_LIST } from '@/constants'

export default {
  name: 'ProductsBannerDesktop',
  props: {
    numberBanner: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    BannerId_1() {
      return BANNERS_PRODUCTS_LIST[this.$config.ENVIRONMENT].banners_id.banner_1
    },
    BannerId_2() {
      return BANNERS_PRODUCTS_LIST[this.$config.ENVIRONMENT].banners_id.banner_2
    },
    BannerId_3() {
      return BANNERS_PRODUCTS_LIST[this.$config.ENVIRONMENT].banners_id.banner_3
    },
    BannerId_4() {
      return BANNERS_PRODUCTS_LIST[this.$config.ENVIRONMENT].banners_id.banner_4
    },
  },
  mounted() {
    // display
    ;(window.globalAmlAds = window.globalAmlAds || []).push(() => {
      globalAml.display(this.BannerId_1)
      globalAml.display(this.BannerId_2)
      globalAml.display(this.BannerId_3)
      globalAml.display(this.BannerId_4)
    })
  },
}
